import React, { useContext, useState } from 'react';
import ReactMarkdown from 'react-markdown'
import { Link, useStaticQuery, graphql } from "gatsby"
import Img, { getImage, StaticImage, GatsbyImage } from "gatsby-plugin-image"


import { LanguageContext } from '../components/LanguageProvider'
import MainLayout from '../components/mainPageLayout'
import SVGLink from '../components/pageLinkSVG.js'




export default function People() {




  const data = useStaticQuery(graphql`
    query  {
      allCmbPagesJson{
        edges{         
        node{
          peoplePage{
                    en{
                        header
                    }
                    hr {
                        header
                    }
                }
            }
        }
    }
      allCmbPeopleJson(
      sort:{
        fields:[surname]
        order:[ASC]
      }
    )
       {
      edges  {
        node {
          id 
          link
          title
          surname
          firstname
          email
          image {childImageSharp {
            gatsbyImageData(
              width: 200
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
            }}
         
          en {
            description
            department
            faculty
            uni
            country
          }
         hr{
          description
            department
            faculty
            uni
            country
    
        }
          
        }
      }
    }
      
    }
      `)



  let people = data.allCmbPeopleJson.edges

  let intro = data.allCmbPagesJson.edges[0].node.peoplePage


 
  let peoplesAttr = people.map(person => <ShortProfile data={person} />)



  return (
    <MainLayout mainHeading={intro}>
      <main className={"shortProfileWrap"}>
        {peoplesAttr}
      </main>
    </MainLayout>
  )
}



function ShortProfile({ data }) {

  let context = useContext(LanguageContext)
  let image = getImage(data.node.image)

  console.log(context)

  let deptOrFak;
  if (data.node[context].department?.length >2) {
    deptOrFak = <dd>{data.node[context].department}</dd>
  } else {
    deptOrFak = <dd>{data.node[context].faculty}</dd>
  }

  let title;
  data.node.title !== null ? title = data.node.title : title = "";
  let name = title + " " + data.node.firstname + " " + data.node.surname;
  let country = data.node[context].country;
  let uni = data.node[context].uni;

  return (
    <>
      <Link className={"shortProfileLink"} to={data.node.link}>
        <dl className={"shortProfile"} key={"a"}>
          <dt key={"b"}>{name}</dt>
          {deptOrFak}
          <dd key={"d"} className ="uni">{uni}</dd>
          <dd key={"d"} className="country"> {country}</dd>
          <dd key={"e"}><GatsbyImage className={"shortProfileImg"} image={image} alt="" /></dd>
        </dl>
        <SVGLink />
      </Link>
    </>)
}
