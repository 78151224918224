import React, {Component} from 'react';





class SVGLink extends Component{




render(){



    return <svg className="plusArrow" width="68.055mm" height="58.219mm" version="1.1" viewBox="0 0 68.055 58.219" xmlns="http://www.w3.org/2000/svg">
    <g transform="translate(-40.908 -98.937)" fill="none" stroke-linecap="round" stroke-linejoin="round">
     <path  transform="scale(.26458)" d="m225.58 584.53a105.34 105.34 0 0 1-61.524-95.798 105.34 105.34 0 0 1 105.34-105.34 105.34 105.34 0 0 1 53.229 14.437" stroke-width="18.898"/>
     <path  d="m67.625 131.43h38.838l-13.479 13.479m-25.359-13.479h38.838l-13.479-13.479" stroke-width="5"/>
    </g>
   </svg>
}

}



export default SVGLink